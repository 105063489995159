<template>
  <div class="column is-12-mobile is-10-tablet is-8-desktop is-6-widescreen is-6-fullhd mb-4">
    <div class="columns is-mobile pt-3 mr-3 is-vcentered px-4">
      <div class="column">
        <nav class="container is-mobile">
            <div class="title-skeleton loading mb-2"></div>
        </nav>
        <div class="has-text-brand-secondary-color">
          <div class="subtitle-skeleton loading"></div>
          <div class="subtitle-skeleton loading mt-2"></div>
          <div class="subtitle-skeleton loading mt-2"></div>
          <div class="subtitle-skeleton loading mt-2"></div>
        </div>
      </div>
    </div>
    <div class="b">
      <div class="card stage-min-size overlapping-container">
        <div class="card-content p-4">
          <div class="columns is-mobile m-0 p-0 is-vcentered">
            <div class="column">
              <div class="container has-text-centered mb-3">
                  <div class="title-skeleton loading"></div>
                  <div class="subtitle-skeleton loading mt-6"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-6"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-6"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-6"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-6"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-6"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-6"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-6"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
                  <div class="subtitle-skeleton loading mt-2"></div>
              </div>
            </div>
          </div>
          <div class="mb-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlapLoadingScreen',
  data () {
    return {
      refresher: null
    }
  },
  mounted () {
    this.refresher = setTimeout(() => {
      window.location.reload()
    }, 25000)
  },
  beforeDestroy() {
    if (this.refresher) {
      clearTimeout(this.refresher);
    }
  }
}
</script>

<style scoped>
.a{
  position: sticky;
  top: 0;
}
</style>
